import {
  assign,
  isEmpty,
  isUndefined,
} from 'lodash';
import axios from 'axios';
import deviceManagementCoreService from '@/device-management/deviceManagementCoreService';
import isDmActiveRuntime from '@/utility/isDmActiveRuntime';
import getCompanyNameNormalized from '@/utility/getCompanyNameNormalized';

const deploymentMap = {
  'nsoft-ticket-preview.pibet.net': 'expo',
  'localhost:8080': 'expo',
  'localhost:8081': 'expo',
  'balkanbet-ticket-preview.pibet.net': 'balkanbet',
  'isi-paray-tick-preview.pibet.net': 'isiparyaj',
};

const deploymentMapDM = {
  'd4ccd338-5093-4c6e-9a42-a769b13f7c2d': 'lutrijabihde',
};

const parseFromQuerystring = (query) => {
  const parsed = {};
  query.substring(1).replace(/([^=&]+)=([^&]*)/g, (m, key, value) => {
    parsed[window.decodeURIComponent(key)] = window.decodeURIComponent(value);
  });

  return parsed;
};

const queryConfig = parseFromQuerystring(window.location.search);
const config = {
  debug: queryConfig.debugMode === 'true',
  pubSubEndpoint: process.env.VUE_APP_LOCAL_PUB_SUB_ENDPOINT,
  subscriptionEndpoint: process.env.VUE_APP_SUBSCRIPTION_ENDPOINT,
  subscriptionChannels: process.env.VUE_APP_SUBSCRIPTION_CHANNELS.split(','),
  subscriptionEvents: process.env.VUE_APP_SUBSCRIPTION_EVENTS.split(','),
};

const isMarketingEnabled = (marketingUrl, appConfigData) => {
  if (!marketingUrl || isEmpty(marketingUrl)) return false;

  return isUndefined(appConfigData.marketingDisabled) ? true : !appConfigData.marketingDisabled;
};

const mapEmptyDmConfig = (configData, fallbackData) => {
  if (configData === 'none' || !configData) {
    return fallbackData;
  }
  return configData;
};

const mapConfig = (data, appConfigData) => {
  const deploymentConfig = data.appSettings.config || {};
  assign(config, deploymentConfig);

  config.debug = mapEmptyDmConfig(appConfigData.debugMode, false);
  config.idleTimeout = config.idleTimeout || process.env.VUE_APP_DEFAULT_IDLE_TIMEOUT;
  config.layout = mapEmptyDmConfig(appConfigData.layout, deploymentConfig.layout || process.env.VUE_APP_DEFAULT_LAYOUT);
  config.betsLayout = mapEmptyDmConfig(appConfigData.betsLayout, deploymentConfig.betsLayout || process.env.VUE_APP_DEFAULT_BETS_LAYOUT);
  config.marketingEnabled = isMarketingEnabled(deploymentConfig.marketingUrl, appConfigData);
};

const getAppConfig = (deployment) => axios.get(
  `${process.env.VUE_APP_GRAVITY_API_ENDPOINT}apps/${deployment}_ticket_preview/settings`,
);

export function loadConfigDM() {
  return deviceManagementCoreService.run()
    .then(() => deviceManagementCoreService.getData()
      .then((deviceData) => {
        // some CMS configs dont match actual company names so first we check for custom mapping
        const deployment = deploymentMapDM[deviceData.device.data.betshop.companyUuid]
          || getCompanyNameNormalized(deviceData.device.data.betshop.companyName);

        return getAppConfig(deployment)
          .then(({ data }) => {
            mapConfig(data, deviceData.appConfig.data);
          });
      }));
}

export function loadConfig() {
  if (isDmActiveRuntime()) {
    return loadConfigDM();
  }
  // query param deployment overrides host - make QA and debugging eqasier
  const deployment = queryConfig.deployment || deploymentMap[window.document.location.host];

  return getAppConfig(deployment)
    .then(({ data }) => {
      mapConfig(data, queryConfig);
    });
}

export default config;
